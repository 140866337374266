import React from 'react';
export const Nav31DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo kh1y7q9r23-editor_css',
    children:
      'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-nootag_logo.svg',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>骄傲之作</p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <p>
                      <a href="#Feature1_0">我们的想法</a>
                    </p>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item~kh1ya5ohmj',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        <a href="#Content9_0">大事件</a>
                      </p>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item~kh1yap7miq',
        className: 'header3-item',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          <a href="#Footer1_1">找到我们</a>
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper',
    playScale: 0.3,
    appear: true,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>做令用户尊敬的产品</p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>nooTag 无派科技</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>2020.03.25 起 / 中国·深圳</p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
  button: {
    className: '',
    children: {
      a: {
        className: 'button',
        href: '#Content5_0',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>了解我们</p>
                </span>
              </span>
            </span>
          </span>
        ),
        type: 'default',
      },
    },
  },
};
export const Content50DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper kh25cpkd3g-editor_css',
  },
  page: { className: 'home-page content5' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>无派科技出品</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>
              迄今为止，无派科技的产品均秉承了优雅的设计和良好的实用性，并受到了用户的一致好评，是我们的骄傲之作。
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper kh1vx8wt1eb-editor_css',
    gutter: 16,
    children: [
      {
        name: 'block~kh25gl78igg',
        className: 'block kh1wcex5a3-editor_css',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kh1wcmgabj-editor_css',
            href: 'https://mp.weixin.qq.com/s/osGnwudrJR8N0GcCaelQhQ',
            target: '_blank',
          },
          img: {
            children:
              'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-%E3%80%8C%E6%B4%BE%E3%80%8D.svg',
            className: 'kh1vyb6tp6i-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <h2>iBeta 尝鲜派</h2>
                        <p>
                          累计服务 1000W+ 用户的 iOS 测试版尝鲜社区。<br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            ),
            className: 'kh23ooeoegt-editor_css',
          },
        },
      },
      {
        name: 'block1',
        className: 'block kh1wcex5a3-editor_css',
        md: 6,
        xs: 24,
        children: {
          wrapper: {
            className: 'content5-block-content kh1wcmgabj-editor_css',
            href: 'https://mp.weixin.qq.com/s/osGnwudrJR8N0GcCaelQhQ',
            target: '_blank',
          },
          img: {
            children:
              'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-%E7%94%BB%E6%9D%BF-2.svg',
            className: 'kh1vyb6tp6i-editor_css',
          },
          content: {
            children: (
              <span>
                <span>
                  <span>
                    <h2>Pasty 水印相机</h2>
                    <p>
                      累积服务 100W+ 用户的特色贴纸相机。<br />
                    </p>
                  </span>
                </span>
              </span>
            ),
            className: 'kh23ooeoegt-editor_css',
          },
        },
      },
    ],
  },
};
export const Feature60DataSource = {
  wrapper: { className: 'home-page-wrapper feature6-wrapper' },
  OverPack: { className: 'home-page feature6', playScale: 0.3 },
  Carousel: {
    className: 'feature6-content',
    dots: false,
    wrapper: { className: 'feature6-content-wrapper' },
    titleWrapper: {
      className: 'feature6-title-wrapper',
      barWrapper: {
        className: 'feature6-title-bar-wrapper',
        children: { className: 'feature6-title-bar' },
      },
      title: { className: 'feature6-title' },
    },
    children: [
      {
        title: {
          className: 'feature6-title-text',
          children: (
            <span>
              <p>数据</p>
            </span>
          ),
        },
        className: 'feature6-item',
        name: 'block0',
        children: [
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child0',
            number: {
              className: 'feature6-number kh1xfk92o7p-editor_css',
              unit: {
                className: 'feature6-unit',
                children: (
                  <span>
                    <p>万以上</p>
                  </span>
                ),
              },
              toText: true,
              children: '2000',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <span>
                    <span>
                      <p>服务用户</p>
                    </span>
                  </span>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper kh1wlvux4uo-editor_css',
            name: 'child1',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit',
                children: (
                  <span>
                    <p>款</p>
                  </span>
                ),
              },
              toText: true,
              children: '2',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>骄傲产品</p>
                </span>
              ),
            },
          },
          {
            md: 8,
            xs: 24,
            className: 'feature6-number-wrapper',
            name: 'child2',
            number: {
              className: 'feature6-number',
              unit: {
                className: 'feature6-unit',
                children: (
                  <span>
                    <p>年</p>
                  </span>
                ),
              },
              toText: true,
              children: '3',
            },
            children: {
              className: 'feature6-text',
              children: (
                <span>
                  <p>开始时间</p>
                </span>
              ),
            },
          },
        ],
      },
    ],
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children:
      'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-heart.0020f07a.png',
    className: 'kh1vl89hij-editor_css',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: (
      <span>
        <p>
          <b>我们的想法</b>
        </p>
      </span>
    ),
  },
  content: {
    className: 'content1-content kh1vme0ulh-editor_css',
    children: (
      <span>
        <p>
          愿景：让互联网属于每个人。<br />
        </p>
        <p>
          使命：以打造受人尊敬、直抵人心的产品为基本，致力于推动、打造去中心化的互联网。<br />
        </p>
        <p>价值观：迎接未来、发掘本质、坚持本心、坦诚平等、彼此信任。</p>
      </span>
    ),
  },
};
export const Content90DataSource = {
  wrapper: { className: 'home-page-wrapper content9-wrapper' },
  page: { className: 'home-page content9' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      {
        name: 'title',
        children: (
          <span>
            <p>大事件</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'timeline',
    children: [
      {
        name: 'block~kh1x5f50x9m',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          content: {
            className: 'block-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      这一步总是要迈出来的，随着产品的不断发展，为了发展和梦想，需要有一个公司的形式将我们自身和成果保护起来，并作为一个实体展现在世界上，在习惯了给他人打标签的时代，无派科技希望做个「无法被定义」的时代开创者。
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
          name: {
            className: 'block-name',
            children: (
              <span>
                <span>
                  <p>无派科技</p>
                </span>
              </span>
            ),
          },
          textWrapper: { className: 'text-wrapper' },
          time: {
            className: 'block-time',
            children: (
              <span>
                <span>
                  <span>
                    <p>2020 年 3 月 25 日</p>
                  </span>
                </span>
              </span>
            ),
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <span>
                  <p>无派科技成立</p>
                </span>
              </span>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <span>
                <span>
                  <span>
                    <p>1.0</p>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'block-img kh1wrvuoebr-editor_css',
            children:
              'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-nootag_logo.svg',
          },
        },
      },
      {
        name: 'block3',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>
                  最早也是出于朋友的需求，使用 iOS
                  中的「快捷指令」实现了给照片添加「用 XXX
                  拍摄」的苹果广告风格和相机型号的照片水印，三年来好评如潮，但抄袭也不断，于是在
                  @Ciyou_lee
                  的帮助下，我们将这个创意用小程序的方式实现，并命名为「Pasty水印相机」，取自「Pasty
                  = Paste it」，给照片贴水印之意。
                </p>
              </span>
            ),
          },
          name: {
            className: 'block-name',
            children: (
              <span>
                <p>Pasty 水印相机</p>
              </span>
            ),
          },
          textWrapper: { className: 'text-wrapper' },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>2019 年 11 月 4 日</p>
              </span>
            ),
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/agOOBdKEIJlQhfeYhHJc.svg',
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>Pasty 水印相机 1.0 发布</p>
              </span>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <span>
                <p>1.0</p>
              </span>
            ),
          },
          img: {
            className: 'block-img',
            children:
              'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-pasty_icon.7dab94d9-1.png',
          },
        },
      },
      {
        name: 'block1',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>
                  第一个版本发布后几个月之内便聚集了 300 万次访问，iOS Beta
                  的体验报告也累计了百万的阅读量，来自世界各地的捐赠累计有了数千元，便有了尝鲜派
                  2.0，<span>
                    在这个版本里，我们进一步把 Apple Beta
                    的更新体验提升，而且有了 0xJacky 和 Hintay 的帮助，我们把 UI
                    更新了、也把 iPSW.me
                    中获取官方固件的接口实现了本地化，解决了访问缓慢的问题，并第一次实现了（应该是国内第一家）从
                    Apple Developer 官网抓取了测试版系统的固件。
                  </span>
                </p>
              </span>
            ),
          },
          name: {
            className: 'block-name',
            children: (
              <span>
                <p>iBeta 尝鲜派</p>
              </span>
            ),
          },
          textWrapper: { className: 'text-wrapper' },
          time: {
            className: 'block-time',
            children: (
              <span>
                <p>2018 年 7 月 23 日</p>
              </span>
            ),
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>iBeta 尝鲜派 2.0 发布</p>
              </span>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <span>
                <p>2.0</p>
              </span>
            ),
          },
          img: {
            className: 'block-img',
            children:
              'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-icon.png',
          },
        },
      },
      {
        name: 'block2',
        className: 'block-wrapper',
        playScale: 0.3,
        children: {
          imgWrapper: { className: 'image-wrapper' },
          content: {
            className: 'block-content',
            children: (
              <span>
                <p>
                  最早出于朋友的需求，一夜时间做了个方便下载「iOS、watchOS、macOS
                  的描述文件」的网页，域名使用「iBeta.me」，由于域名简洁，页面直接，功能直戳重点，发布后收到大家的一直好评，一度造成服务器堵塞。
                </p>
              </span>
            ),
          },
          name: {
            className: 'block-name',
            children: (
              <span>
                <p>iBeta 尝鲜派</p>
              </span>
            ),
          },
          textWrapper: { className: 'text-wrapper' },
          time: {
            className: 'block-time',
            children: (
              <span>
                <span>
                  <p>2017 年 10 月 21 日</p>
                </span>
              </span>
            ),
          },
          icon: {
            className: 'block-icon',
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/QviGtUPvTFxdhsTUAacr.svg',
          },
          title: {
            className: 'block-title',
            children: (
              <span>
                <p>iBeta 尝鲜派 1.0 发布</p>
              </span>
            ),
          },
          post: {
            className: 'block-post',
            children: (
              <span>
                <span>
                  <span>
                    <p>1.0</p>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'block-img',
            children:
              'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-logo.43017021.png',
          },
        },
      },
    ],
  },
};
export const Teams00DataSource = {
  wrapper: { className: 'home-page-wrapper teams0-wrapper' },
  OverPack: { playScale: 0.3, className: 'home-page teams0' },
  BannerAnim: {
    className: 'banner-anim',
    children: [
      {
        name: 'elem~kh1z2dznw1m',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          children: [
            {
              name: 'image',
              children:
                'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-A-njqxS5Ky7CQAAAAAAAAAAABjARQnAQ.jpg',
              className: 'teams0-image kh1yt9xpxnj-editor_css',
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>“求知若渴，虚怀若愚。“</p>
                  </span>
                </span>
              ),
              className: 'teams0-content',
            },
            {
              name: 'title',
              children: (
                <span>
                  <span>
                    <p>史蒂夫 · 乔布斯</p>
                  </span>
                </span>
              ),
              className: 'teams0-h1',
            },
          ],
        },
      },
      {
        name: 'elem~kh1z9cxpjs8',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          children: [
            {
              name: 'image',
              children:
                'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-A-njqxS5Ky7CQAAAAAAAAAAABjARQnAQ%E5%89%AF%E6%9C%AC.png',
              className: 'teams0-image kh1yt9xpxnj-editor_css',
            },
            {
              name: 'content',
              children: (
                <span>
                  <p>“不要因为走的太远，就忘了当初为什么出发。“</p>
                </span>
              ),
              className: 'teams0-content',
            },
            {
              name: 'title',
              children: (
                <span>
                  <p>纪伯伦</p>
                </span>
              ),
              className: 'teams0-h1',
            },
          ],
        },
      },
      {
        name: 'elem0',
        className: 'teams0-banner-user-elem',
        titleWrapper: {
          className: 'teams0-content-wrapper',
          children: [
            {
              name: 'image',
              children:
                'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-A-njqxS5Ky7CQAAAAAAAAAAABjARQnAQ%E5%89%AF%E6%9C%AC.jpg',
              className: 'teams0-image kh1yt9xpxnj-editor_css',
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>“Good artists copy, Great artists steal.“</p>
                  </span>
                </span>
              ),
              className: 'teams0-content',
            },
            {
              name: 'title',
              children: (
                <span>
                  <p>毕加索</p>
                </span>
              ),
              className: 'teams0-h1',
            },
          ],
        },
      },
    ],
  },
};
export const Footer11DataSource = {
  wrapper: { className: 'home-page-wrapper footer1-wrapper' },
  OverPack: { className: 'footer1', playScale: 0.2 },
  block: {
    className: 'home-page',
    gutter: 0,
    children: [
      {
        name: 'block0',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          className: 'logo',
          children:
            'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-nootag_logo_white.svg',
        },
        childWrapper: {
          className: 'slogan',
          children: [
            {
              name: 'content0',
              children: (
                <span>
                  <span>
                    <p>做令用户尊敬的产品</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>找到我们</p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: 'https://weibo.com/u/6432700811',
              children: (
                <span>
                  <p>iBeta 尝鲜派</p>
                </span>
              ),
              target: '_blank',
            },
            {
              name: 'link1',
              href: 'https://weibo.com/u/5838111067',
              children: (
                <span>
                  <p>Pasty 水印相机</p>
                </span>
              ),
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'block~kh1xpe9z54s',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <span>
                <p>联系我们</p>
              </span>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href: 'mailto:i@Sunbelife.com',
              children: (
                <span>
                  <span>
                    <span>
                      <p>i@Sunbelife.com</p>
                    </span>
                  </span>
                </span>
              ),
              target: '_blank',
            },
          ],
        },
      },
      {
        name: 'block~kh1xlvyxp67',
        xs: 24,
        md: 6,
        className: 'block',
        title: {
          children: (
            <span>
              <p>隐私协议</p>
            </span>
          ),
        },
        childWrapper: {
          children: [
            {
              name: 'link0',
              href:
                'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-ibeta.pdf',
              children: (
                <span>
                  <span>
                    <p>iBeta 尝鲜派</p>
                  </span>
                </span>
              ),
              target: '_blank',
            },
            {
              name: 'link1',
              href:
                'https://yuanye-wx.oss-cn-beijing.aliyuncs.com/2020-11-03-pasty.pdf',
              children: (
                <span>
                  <p>Pasty 水印相机</p>
                </span>
              ),
              target: '_blank',
            },
          ],
        },
      },
    ],
  },
  copyrightWrapper: { className: 'copyright-wrapper' },
  copyrightPage: { className: 'home-page' },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          &nbsp;© 2020 nooTag Tech · 无派科技（深圳）有限公司 ·&nbsp;{' '}
          <a href="https://beian.miit.gov.cn">粤ICP备20024314号</a>
          <br />
        </span>
      </span>
    ),
  },
};
